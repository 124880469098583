export const homePhotos = [
  {
    name: "Home1",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Home4.jpg?alt=media&token=c9deb94f-ff79-4a17-aa7d-2c60509cfdc4",
  },
  {
    name: "Home2",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Home2.jpg?alt=media&token=d7f0c62d-5d51-4c13-85e9-ebf808f3511b",
  },
  {
    name: "Home3",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Home3.jpg?alt=media&token=43e62531-5303-4a65-b650-00b178d7f318",
  },
  {
    name: "Home4",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Home1.jpg?alt=media&token=cae5af5e-04b4-476b-ae90-8d544c9a5e5b",
  },
  {
    name: "Home5",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Home5.jpg?alt=media&token=9a4ac1bb-4e14-4b24-b152-3a0b7dab9b8e",
  },
];

export const gallery = [
  {
    name: "Gallery1",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery1.jpg?alt=media&token=76b0c122-1aa0-4581-826a-7780865140b3",
  },
  {
    name: "Gallery2",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery2.jpg?alt=media&token=e1fb05e8-5e42-42ae-88a3-fe5f39fc00cc",
  },
  {
    name: "Gallery3",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery3.jpg?alt=media&token=6653618a-d228-4e85-a0d3-e12641835f27",
  },
  {
    name: "Gallery4",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery4.jpg?alt=media&token=a57254ab-ed8d-4e9e-bd79-7fd5912dc96c",
  },
  {
    name: "Gallery5",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery5.jpg?alt=media&token=36cacbc4-1479-4cf7-a1ad-a87a9fa9c0fd",
  },
  {
    name: "Gallery6",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery6.jpg?alt=media&token=d7e35757-469b-4cc0-99cf-349b1b9e2807",
  },
  {
    name: "Gallery7",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery7.jpg?alt=media&token=8f9705fc-26e6-4cc1-a025-53f451a21f12",
  },
  {
    name: "Gallery8",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery8.jpg?alt=media&token=58a7b360-5b1f-4e67-8720-7819587ea008",
  },
  {
    name: "Gallery9",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery9.jpg?alt=media&token=ce2abd3b-5768-40e4-af10-db3fd4621d8b",
  },
  {
    name: "Gallery10",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery10.jpg?alt=media&token=a7bbe233-a0f6-4447-8ef8-1f02a0feee8b",
  },
  {
    name: "Gallery11",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery11.jpg?alt=media&token=047e8090-1dad-4cfd-b60d-b663673472b3",
  },
  {
    name: "Gallery12",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery12.jpg?alt=media&token=8e14bb87-1e98-45bf-a6ed-f3f72957bce3",
  },
  {
    name: "Gallery13",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery13.jpg?alt=media&token=aec5cb02-caff-4d41-ae53-5ffefa1098da",
  },
  {
    name: "Gallery14",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery14.jpg?alt=media&token=4dea01d4-cc8d-4c18-9f6a-e78cd4ff8224",
  },
  {
    name: "Gallery15",
    url: "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/Gallery15.jpg?alt=media&token=abf37bfd-0890-4649-9f23-46df9f9c1880",
  },
];
