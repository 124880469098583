import { Box, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { Colors } from "../styles/globalStyles";

import { homePhotos } from "../utils/utils";

const Home = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "80vh",
      }}
    >
      <Typography
        variant="h5"
        fontFamily="Jost"
        sx={{ color: Colors.secondary }}
      >
        Nina Captures Moments
      </Typography>
      <Carousel
        sx={{
          backgroundColor: "#FFF",
          height: "80vh",
          width: { xs: "250px", sm: "600px" },
        }}
        indicators={false}
        height={"80vh"}
        duration={300}
      >
        {homePhotos.map((item, i) => (
          <img
            key={i}
            src={item.url}
            alt={item.name}
            height={"100%"}
            width={"100%"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              alignContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 20,
              objectFit: "contain",
            }}
          />
        ))}
      </Carousel>
    </Box>
  );
};

export default Home;
