import { Box, Typography, TextField, Button } from "@mui/material";
import { useRef, useState } from "react";
import { Colors } from "../styles/globalStyles";
import InstagramIcon from "@mui/icons-material/Instagram";
import emailjs from "@emailjs/browser";

type InitialForm = {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
};

const initialFormValues: InitialForm = {
  firstName: "",
  lastName: "",
  email: "",
  subject: "",
  message: "",
};

const Contact = () => {
  const [values, setValues] = useState<InitialForm>(initialFormValues);

  const [errors, setErrors] = useState({} as any);

  const form: any = useRef();

  const validate: any = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }
    if ("firstName" in fieldValues)
      temp.fullName = fieldValues.firstName ? "" : "This field is required.";
    if ("lastName" in fieldValues)
      temp.fullName = fieldValues.lastName ? "" : "This field is required.";

    if ("subject" in fieldValues)
      temp.message = fieldValues.subject ? "" : "This field is required.";

    if ("message" in fieldValues)
      temp.message = fieldValues.message ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    emailjs.sendForm(
      "service_bnt298q",
      "template_92b1v6p",
      form?.current,
      "bUSS4lbBe7U-gkThr"
    );
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.email &&
      fieldValues.firstName &&
      fieldValues.lastName &&
      fieldValues.subject &&
      fieldValues.message &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h5"
        fontFamily="Jost"
        sx={{ color: Colors.secondary, marginBottom: 5 }}
      >
        CONTACT
      </Typography>
      <Box
        sx={{
          alignItems: "center",
          alignContent: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: "#FFF",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: { xs: "100vw", sm: "500px" },
            backgroundColor: "#FFF",
          }}
        >
          <Typography
            variant="subtitle1"
            fontFamily="Jost"
            sx={{ color: Colors.secondary, marginBottom: 3, marginTop: 5 }}
          >
            STUDIO
          </Typography>
          <Typography
            variant="subtitle1"
            fontFamily="Jost"
            sx={{ color: Colors.secondary, marginBottom: 5 }}
            textAlign="center"
          >
            Beautiful places of your choice <br />
            Currently based in Vienna
          </Typography>
          <Typography
            variant="subtitle1"
            fontFamily="Jost"
            sx={{ color: Colors.secondary, marginBottom: 3 }}
          >
            I SPEAK
          </Typography>
          <Typography
            variant="subtitle1"
            fontFamily="Jost"
            sx={{ color: Colors.secondary, marginBottom: 5 }}
            textAlign="center"
          >
            English <br />
            Indonesian <br />
            German <br />
            Japanese
          </Typography>
        </Box>
        <Box
          component="form"
          ref={form}
          onSubmit={handleFormSubmit}
          sx={{
            display: "flex",
            flexDirection: { xs: "column" },
          }}
        >
          <TextField
            id="firstName"
            required
            label="First Name"
            sx={{
              "& label": {
                color: Colors.secondary,
              },
              "& label.Mui-focused": {
                color: Colors.secondary,
              },
              "& legend": {
                color: Colors.secondary,
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: Colors.secondary,
              },
            }}
            InputProps={{ sx: { color: Colors.secondary } }}
            variant="standard"
            size="medium"
            margin="normal"
            name="firstName"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            {...(errors["firstName"] && {
              error: true,
              helperText: errors["firstName"],
            })}
          />
          <TextField
            id="lastName"
            required
            label="Last Name"
            sx={{
              "& label": {
                color: Colors.secondary,
              },
              "& label.Mui-focused": {
                color: Colors.secondary,
              },
              "& legend": {
                color: Colors.secondary,
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: Colors.secondary,
              },
            }}
            variant="standard"
            size="medium"
            margin="normal"
            name="lastName"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            {...(errors["lastName"] && {
              error: true,
              helperText: errors["lastName"],
            })}
          />
          <TextField
            id="email"
            required
            label="E-mail"
            sx={{
              "& label": {
                color: Colors.secondary,
              },
              "& label.Mui-focused": {
                color: Colors.secondary,
              },
              "& legend": {
                color: Colors.secondary,
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: Colors.secondary,
              },
            }}
            variant="standard"
            type="email"
            size="medium"
            margin="normal"
            name="email"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            {...(errors["email"] && {
              error: true,
              helperText: errors["email"],
            })}
          />

          <TextField
            id="subject"
            required
            label="Subject"
            sx={{
              "& label": {
                color: Colors.secondary,
              },
              "& label.Mui-focused": {
                color: Colors.secondary,
              },
              "& legend": {
                color: Colors.secondary,
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: Colors.secondary,
              },
            }}
            variant="standard"
            size="medium"
            margin="normal"
            name="subject"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            {...(errors["subject"] && {
              error: true,
              helperText: errors["subject"],
            })}
          />

          <TextField
            id="message"
            required
            multiline
            rows={4}
            label="Leave me a message"
            sx={{
              "& label": {
                color: Colors.secondary,
              },
              "& label.Mui-focused": {
                color: Colors.secondary,
              },
              "& legend": {
                color: Colors.secondary,
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: Colors.secondary,
              },
            }}
            variant="standard"
            size="medium"
            margin="normal"
            name="message"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            {...(errors["message"] && {
              error: true,
              helperText: errors["messages"],
            })}
          />
          <Button
            disabled={!formIsValid()}
            type="submit"
            variant="outlined"
            onClick={handleFormSubmit}
            sx={{
              color: Colors.secondary,
              backgroundColor: Colors.extra,
              borderColor: Colors.secondary,
              marginTop: 2,
              "&:hover": {
                cursor: "pointer",
                backgroundColor: Colors.secondary,
                color: Colors.extra,
                borderColor: Colors.extra,
              },
            }}
          >
            Submit
          </Button>
          <Typography
            variant="subtitle2"
            fontFamily="Jost"
            sx={{ color: Colors.secondary, marginBottom: 5, marginTop: 2 }}
            textAlign="center"
          >
            Thank you for submitting!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: { xs: "100vw", sm: "500px" },
            backgroundColor: "#FFF",
          }}
        >
          <Typography
            variant="subtitle1"
            fontFamily="Jost"
            sx={{ color: Colors.secondary, marginBottom: 3, marginTop: 5 }}
            textAlign="center"
          >
            GENERAL ENQUIRIES
          </Typography>
          <Typography
            variant="subtitle1"
            fontFamily="Jost"
            sx={{ color: Colors.secondary, marginBottom: 2 }}
            textAlign="center"
          >
            imaninasubki@gmail.com
          </Typography>
          <InstagramIcon
            sx={{
              color: Colors.secondary,
              marginBottom: 4,
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              window.open(
                "https://www.instagram.com/ninacapturesmoments/?hl=en"
              );
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
