import { Box, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../styles/globalStyles";

const AboutMe = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginX: 10,
      }}
    >
      <Typography
        variant="h5"
        fontFamily="Jost"
        sx={{ color: Colors.secondary, marginBottom: 5 }}
      >
        ABOUT NINA SUBKI
      </Typography>
      <Box
        fontFamily="Jost"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
          height: { xs: "800px", sm: "500px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            flex: 0.5,
            marginRight: { sm: 10 },
          }}
        >
          <Typography textAlign="justify" sx={{ marginBottom: { xs: 2 } }}>
            Pictures are not only meant to be seen. I believe that pictures are
            intended to be felt too. That's why I am here to capture your
            special and photogenic moments. Whether you are simply walking down
            the street, sipping a cup of tea, celebrating a joyful time with
            your loved ones, or even when you are just laying down with a book
            in your hand, it is very possible to have your photogenic moments
            captured. My goal is to deliver photographic records of those
            beautiful moments of my clients. <br />I offer individual portraits,
            family portraits, maternity shoots, friendship portraits, love
            story/couple portraits, and creative storytelling! Please let me
            know the occasion when you want your beautiful moments to be
            captured so we can discuss the concept in advance together!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 0.5,
            marginLeft: { sm: 10 },
            height: { xs: "800px", sm: "500px" },
          }}
        >
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/ninacapturesmoments.appspot.com/o/about_me.jpg?alt=media&token=618130d2-0f85-49b1-92a8-381fda0cb9e0"
            }
            alt={"Nina Subki"}
            style={{ objectFit: "contain", width: "100%", height: "100%" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AboutMe;
