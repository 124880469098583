import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Colors } from "../styles/globalStyles";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { gallery } from "../utils/utils";

const Portraits = () => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        margin: 5,
      }}
    >
      <Typography
        variant="h5"
        fontFamily="Jost"
        sx={{ color: Colors.secondary }}
      >
        PORTRAITS
      </Typography>
      <ImageList
        sx={{ width: { xs: 300, sm: 500 }, overflow: "hidden" }}
        cols={matches ? 3 : 1}
        rowHeight={matches ? 250 : 300}
        gap={matches ? 4 : 2}
      >
        {gallery.map((image, idx) => (
          <ImageListItem key={idx}>
            <img
              src={image.url}
              srcSet={image.url}
              alt={image.name}
              loading="lazy"
              style={{
                objectFit: "contain",
                height: "100%",
                width: "100%",
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export default Portraits;
