import { Box, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../styles/globalStyles";

const Footer = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        width: "100vw",
        bottom: 0,
        position: "sticky",
        marginTop: 5,
      }}
    >
      <Typography
        variant="caption"
        fontFamily="Jost"
        sx={{
          color: Colors.secondary,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => {
          window.open("https://portfolio-ivory-seven-37.vercel.app/");
        }}
      >
        Created by Federico Verly
      </Typography>
    </Box>
  );
};

export default Footer;
