import React from "react";
import { Box } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import AboutMe from "./components/AboutMe";
import Portraits from "./components/Portraits";
import Contact from "./components/Contact";

function App() {
  return (
    <BrowserRouter>
      <Box
        sx={{
          backgroundColor: "#FFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Navbar />
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/portraits"} element={<Portraits />} />
          <Route path={"/aboutme"} element={<AboutMe />} />
          <Route path={"/contact"} element={<Contact />} />
        </Routes>
        <Footer />
      </Box>
    </BrowserRouter>
  );
}

export default App;
