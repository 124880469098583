import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Colors } from "../styles/globalStyles";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: Colors.background,
        marginBottom: 4,
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          to="/"
          style={{
            display: "flex",
            alignItems: "center",
            outline: "none",
            textDecoration: "none",
            marginRight: 70,
            color: `${Colors.primary}`,
          }}
        >
          <Typography
            fontFamily="Jost"
            fontWeight={300}
            sx={{ fontSize: { xs: 14, md: 24 } }}
          >
            Nina | Portrait Photographer
          </Typography>
        </Link>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Link
            to="/portraits"
            style={{
              display: "flex",
              alignItems: "center",
              outline: "none",
              marginRight: 35,
              textDecoration: "none",
              color: `${Colors.primary}`,
              alignContent: "center",
              justifyContent: "center",
            }}
            onClick={toggleDrawer(false)}
          >
            <Typography
              fontFamily="Jost"
              fontWeight={300}
              variant="h6"
              textAlign="center"
            >
              Portraits
            </Typography>
          </Link>
          <Link
            to="/aboutme"
            style={{
              display: "flex",
              alignItems: "center",
              outline: "none",
              marginRight: 35,
              textDecoration: "none",
              color: `${Colors.primary}`,
              alignContent: "center",
              justifyContent: "center",
            }}
            onClick={toggleDrawer(false)}
          >
            <Typography fontFamily="Jost" fontWeight={300} variant="h6">
              About
            </Typography>
          </Link>
          <Link
            to="/contact"
            style={{
              display: "flex",
              alignItems: "center",
              outline: "none",
              marginRight: 35,
              textDecoration: "none",
              color: `${Colors.primary}`,
              alignContent: "center",
              justifyContent: "center",
            }}
            onClick={toggleDrawer(false)}
          >
            <Typography fontFamily="Jost" fontWeight={300} variant="h6">
              Contact
            </Typography>
          </Link>
        </Box>
        <IconButton
          edge="end"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
          sx={{
            mr: 2,
            display: { xs: "block", sm: "none" },
            alignItems: "center",
            justifyContent: "center",
            color: Colors.primary,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          anchor="right"
          variant="temporary"
          open={open}
          onClose={toggleDrawer(false)}
          sx={{
            backgroundColor: Colors.background,
          }}
        >
          <Box
            sx={{
              backgroundColor: Colors.background,
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to="/portraits"
              style={{
                display: "flex",
                alignItems: "center",
                outline: "none",
                textDecoration: "none",
                color: `${Colors.primary}`,
                height: "33vh",
                alignContent: "center",
                justifyContent: "center",
              }}
              onClick={toggleDrawer(false)}
            >
              <Typography
                fontFamily="Jost"
                fontWeight={300}
                variant="h6"
                textAlign="center"
              >
                Portraits
              </Typography>
            </Link>
            <Link
              to="/aboutme"
              style={{
                display: "flex",
                alignItems: "center",
                outline: "none",
                textDecoration: "none",
                color: `${Colors.primary}`,
                height: "33vh",
                alignContent: "center",
                justifyContent: "center",
              }}
              onClick={toggleDrawer(false)}
            >
              <Typography fontFamily="Jost" fontWeight={300} variant="h6">
                About
              </Typography>
            </Link>
            <Link
              to="/contact"
              style={{
                display: "flex",
                alignItems: "center",
                outline: "none",
                textDecoration: "none",
                color: `${Colors.primary}`,
                height: "33vh",
                alignContent: "center",
                justifyContent: "center",
              }}
              onClick={toggleDrawer(false)}
            >
              <Typography fontFamily="Jost" fontWeight={300} variant="h6">
                Contact
              </Typography>
            </Link>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
